html {
  scrollbar-width: thin;
}

body {
  margin: 0;
  padding: 0;
  background: #f2f1f9 !important;

}

/* layout */
.main {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main.toggled {
  padding-left: 250px;
  overflow: scroll;
}

::placeholder {
  color: #8e8e8eab !important;
  opacity: 1;
}

.left-section {
  z-index: 99;
}

.right-section {
  z-index: 98;
}

.main.active {
  left: 80px;
  transition: 0.5s all;
}

.main.toggled .left-section {
  width: 250px;
}

#page-content-wrapper {
  position: absolute;
  padding: 15px;
  width: 100%;
  overflow-x: hidden;
}



/* sidebar */


.side-bar {
  background: #fff;
  width: 250px;
  min-height: 95vh;
  position: fixed;
  top: 24px;
  left: 18px;
  overflow-y: auto;
  transition-property: left;
  border-radius: 15px;
  transition: 0.6s ease;


}

.side-bar::-webkit-scrollbar {
  width: 0px;
}

.right-section {
  margin: 0 18px 0 290px;
  transition: 0.6s ease;

}

nav.bg-white.navbar.navbar-expand-lg.navbar-light {
  border-radius: 11px;
  top: 27%;

}

.header-section {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  right: 1%;
  padding-left: 304px;
  background: #f2f1f9;
  height: 85px;
  transition: 0.6s ease;
}

.content-wrapper {
  margin-top: 80px;
}

.side-bar.active {
  left: 0;
}

.side-bar .menu {
  width: 100%;
  margin-bottom: 20px;
  /* overflow: auto;
  height: 400px; */
}

.side-bar .menu .item {
  position: relative;
  cursor: pointer;
}

.side-bar .menu .item a {
  color: #464255;
  font-size: 16px;
  text-decoration: none;
  display: block;
  padding: 3px 30px;
  line-height: 50px;
  margin-left: 12px;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.side-bar .menu .item i {
  margin-right: 15px;
}

.side-bar .menu .item a .dropdown {
  position: absolute;
  right: 0;
  margin: 20px;
  transition: 0.3s ease;
}



.rotate {
  transform: rotate(90deg);
}

.brandArea {
  padding: 22px;
  transition: 0.6s ease;
}

.settingsSection {
  background: #33995e;
  color: #fff;
  border-radius: 17px;
  padding: 15px 15px 25px 15px;
  background-image: url('../src/assets/image/settingsBg.png');
  background-size: unset;
  background-repeat: no-repeat;
  background-position: right;
}


.logoutbtnArea {
  margin-top: 50px;
}

.side-bar .btn-dark {
  background: #000;
  width: 100%;
  text-align: left;
  border-radius: 19px;
}

.brandArea img {
  height: 80px;
  margin-top: 20px;
  transition: 0.6s ease;
}

.menu img {
  margin-right: 10px;
}


.nav-active {
  background: #f2f1f9;
  border-radius: 27.4px 0px 0px 27.4px;

}

.nav-active::after {
  content: '';
  border-bottom-right-radius: 30px;
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 61px;
  height: 61px;
  top: -61px;
  box-shadow: 8px 20px #f2f1f9;
  background: transparent;
  transition: 0.3s ease-in-out;
}

.nav-active::before {
  content: '';
  border-top-right-radius: 30px;
  position: absolute;
  right: 0px;
  width: 61px;
  height: 61px;
  top: 56px;
  box-shadow: 11px -16px #f2f1f9;
  background: transparent;
  transition: 0.3s ease-in-out;
}

.nav-active .sub-menu {
  display: block;
}

.settingsSection .btn {
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  border-radius: 13px;
  height: 38px;
  color: #2D3748;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.settingsSection h5 {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.settingsSection p {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

/* toggle */

.toggleactive .side-bar {
  width: 80px;
  transition: 0.6s ease;
}

.toggleactive .header-section {
  padding-left: 127px;
  transition: 0.6s ease;
}

.toggleactive .right-section {
  margin: 0 18px 0 120px;
  transition: 0.6s ease;
}

.toggleactive .brandArea {
  padding: 0px 0px 18px 0px;
}

.toggleactive .brandArea img {
  height: 25px;
  margin-top: 20px;
}

.toggleactive .menu span, .toggleactive .menu .dropdown {
  display: none;
  transition: 0.6s ease;
}

.side-bar .menu .item a {
  padding: 3px 30px 3px 18px;
  transition: 0.6s ease;
}

.toggleactive .side-bar .btn-dark span {
  display: none;
  transition: 0.6s ease;
}

.toggleactive .side-bar .btn-dark {
  width: 100%;
  text-align: center;
  padding: 5px 10px 5px 9px;
  transition: 0.6s ease;
}

.toggleactive .settingsSection h5, .toggleactive .settingsSection p, .toggleactive .settingsSection .btn {
  display: none;
}

.toggleactive .settingsSection {
  padding: 6px 4px 6px 5px;
  margin: 195px 16px 0 0px;
  width: 100%;
}

.toggleactive .settingsSection img {
  margin-bottom: 0 !important;
}

.toggleactive .nav-active::before, .toggleactive .nav-active::after {
  width: 50px;
  transition: 0.3s ease-in-out;
}

.toggleactive .side-bar {
  overflow: hidden;
}

.sidebar-toggle, .right-angle {
  display: none;
}

.sidebar-toggle svg, .right-angle svg {
  position: absolute;
  right: 0;
  background: #33995e;
  color: #fff;
  height: 15px;
  border-radius: 4px;
  padding: 10px;
  top: 1%;
}


.btn-green {
  color: #6ac56a !important;
  border: 1px solid #6ac56a !important;
}

.adminUser {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.subName {
  font-size: 10px;
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #4CAF50;
  border-radius: 50%;
  color: white;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: end;
}


.notification-bell {
  width: 24px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.notification-count {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #f44336;
  color: white;
  border-radius: 50%;
  font-size: 10px;
  padding: 1px 6px 2px 6px;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-info {
  margin-left: 10px;
}

.profile-name {
  color: #484848;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.profile-title {
  font-size: 11px;
  color: #484848;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.dropdown-arrow {
  width: 10px;
  height: 10px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  transform: rotate(134deg);
  margin: 0 16px;
}

/* notification start */
.notification-section {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.notification-section .card {
  border-radius: 14px;
}

.notification {
  border-bottom: 1px solid #eee;
}

.form-check.form-switch {
  display: flex;
  gap: 50px;
}

.form-check-input:focus {
  box-shadow: none !important;

}

.notification:nth-child(odd) {
  background-color: #F5F5F5;
}



.notification-content {
  display: flex;
  gap: 10px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;

}

.card-footer.d-flex.justify-content-between .btn {
  font-size: 14px;
  border: 1px solid #ccc;
}

.notification:last-child {
  border-bottom: none;
}





.notification-content strong {
  display: block;
  font-weight: bold;
}

.time {
  font-size: 0.8em;
  color: #999;
  width: 70px;
  display: flex;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.notification-section {
  display: none;
}

.text_Area strong {
  font-size: 14px;
  font-family: 'Barlow';
  font-weight: 600;

}

.card-body.p-0 {
  min-height: 300px;
}

.text_Area P {
  FONT-SIZE: 11px;
  font-family: 'Barlow';
  font-weight: 400;
  margin-bottom: 0;
}

.ActiveNotification .notification-section {
  display: block;
  position: absolute;
  z-index: 999;
  top: 54px;
  right: 0;
  transition: 0.3s ease-in-out;
}

/* notification end */
/* ======= */
.ProfileArea .dropdown-content {
  display: none;
}

.dynaMCLass .dropdown-content {
  display: block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  width: 195px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  right: -12px;
  top: 60px;

}

.dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  display: flex;
  align-items: center;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown-content img {
  margin-right: 10px;
}


/* loader  */

.dot-one {
  animation: dot-one 2s infinite linear
}

.dot-two {
  animation: dot-two 2s infinite linear
}

.dot-three {
  animation: dot-three 2s infinite linear
}

@keyframes dot-one {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.sub-menu {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}

a.sub-item svg {
  font-size: 14px;
}

a.sub-item.active svg {
  color: #33995e;
}

a.sub-item.active span {
  color: #33995e;

}