@media only screen and (min-device-width: 1580px) and (max-device-width: 1883px) and (orientation:landscape) {

    /* For landscape layouts only */
    .carousel-slider {
        bottom: -84px;
        right: -152px;
    }
}

@media only screen and (min-device-width: 1500px) and (max-device-width: 1600px) and (orientation:landscape) {

    .carousel-slider {
        bottom: -75px;
        right: -109px;
    }

}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) and (orientation:landscape) {

    .carousel-slider {
        bottom: -4px;
        right: -94px;
    }

    .carousel-indicators {
        bottom: -26px !important;
    }

}

@media only screen and (min-device-width: 992px) and (max-device-width: 1200px) and (orientation:landscape) {

    .carousel-slider {
        bottom: 55px;
        right: -80px;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .form-check.remember, .forgetbtn, .form_text .sub-heading {
        font-size: 12px;
    }

    .ActiveNotification .notification-section {
        top: 66px;
        left: -120px !important;
    }

    .job_details .d-flex.justify-content-between.align-items-center {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .breadcrumb a {
        font-size: 15px;
    }

    .search-container input {
        width: 170px;
    }

    .title-container :last-child {
        opacity: 0;
    }

    .BreakevenJobCostText {
        grid-template-columns: 1fr;
        margin: 0px 0;
    }

    .BreakevenJobCostText p {
        margin: 7px;
    }

    .header-section .me-4, .navbar-brand {
        margin-right: 0 !important;
    }

    .header {
        font-size: 18px !important;
    }

    .secondChart .position-absolute img {
        width: 35px !important;
    }
}

@media (max-width: 480px) {
    .tooltiptext {
        width: 200px;
        left: 90%;
        padding: 2px 4px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {

    .tooltip_text {
        width: 400px !important;
        bottom: -62px !important;
        left: -218px !important;
    }

    .card_general_info {
        z-index: -1;
    }

    .cardSection .col-sm-4 {
        width: 100%;
    }

    .chart-container .col-sm-3 {
        order: 1;
        width: 100%;
    }

    .chart-container .col-sm-6 {
        width: 100%;
    }

    .secondChart .position-absolute img {
        width: 39px;
    }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (max-width: 991px) {
    .left_icon {
        left: 2%;
    }

    /* login ,sign up,forgot password,create password,reset password,otp==========Start */
    .slider-left-side {
        display: none;
    }

    .sidebar-toggle {
        display: block;
    }

    .right-angle {
        display: block;
    }

    .login-form {
        width: 100%;
    }

    .container-fluid.bg-white.custom-parent {
        background-image: url('../image/bgdown.png'), url('../image/bgleft.png'), url('../image/bgright.png');
        background-position: bottom, top left, top right;
        background-repeat: no-repeat, repeat, repeat;
        background-size: contain, contain, contain;

    }

    .form_text .heading span {
        display: none;
    }

    .container-fluid.bg-white.custom-parent .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
    }

    .container-fluid.bg-white.custom-parent .logo img {
        width: auto;
    }

    .container-fluid.bg-white.custom-parent .form_text {
        margin-bottom: 25%;
    }

    .container-fluid.bg-white.custom-parent.signUp_page .logo {
        margin-bottom: 2%;
    }

    .heading .main-heading {
        font-size: 1.5rem;
    }

    .toggleactive .header-section {
        padding-left: 10px;
        transition: 0.6s ease;
    }


    /* login ,sign up,forgot password,create password,reset password,otp==========End */

    /* dashboard */
    .side-bar {
        left: calc(-250px - 18px);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 0;
        height: 100vh;
        top: 0;
    }

    .right-section {
        margin: 0 18px 0 18px !important;
        transition: 0.6s ease;
    }

    .brandArea {
        padding: 0;
    }

    .header-section {
        padding-left: 25px;
        padding-right: 14px;
        transition: 0.6s ease;
    }

    .toggleactive .side-bar {
        width: 250px;
        left: 0;
        top: 0;
        z-index: 999;
        min-height: 100vh;
        border-radius: 0;
        transition: 0.6s ease;
    }

    .side-bar .menu .item a {
        padding: 3px 30px 3px 18px;
        transition: 0.6s ease;
        display: flex;
        align-items: center;
    }

    .toggleactive .brandArea img {
        height: 70px;
    }

    .toggleactive .settingsSection {
        padding: 6px 4px 6px 5px;
        margin: 0;
        width: 100%;

    }

    .toggleactive .settingsSection h5, .toggleactive .settingsSection p, .toggleactive .settingsSection .btn {
        display: block;
    }

    .settingsSection h5 {
        margin: 10px 0;
    }


    .toggleactive .menu span, .toggleactive .menu .dropdown {
        display: block;
        transition: 0.6s ease;
    }

    .toggleactive .side-bar .btn-dark {

        display: flex;
        align-items: center;
        gap: 10px;
    }

    .side-bar .menu {
        width: 100%;
        margin-bottom: 0;
    }

    .toggleactive .side-bar .btn-dark span {
        display: block;
        transition: 0.6s ease;
    }



    .navbar-nav {
        display: grid !important;
        grid-template-columns: 50px 1fr;
    }

    .gaugeChart1, .gaugeChart2, .gaugeChart3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header {
        font-size: 20px;
    }




    .nextBtn .btn, .prevBtn .btn {
        font-size: 18px;
    }

    .secondText strong {
        font-size: 15px;
    }

    .ActiveNotification .notification-section {
        top: 63px;
        left: -86px;
        transition: 0.3s ease-in-out;

    }

    .image-container {
        width: 200px;
        margin: 0 auto;
    }


}




@media screen and (max-device-width: 991px) and (orientation: landscape) {
    .toggleactive .side-bar {
        margin-top: 8%;
    }

    .cardSection .col-sm-4 {
        width: 100%;
    }

    .chart-container .col-sm-3 {
        order: 1;
        width: 50%;
    }

    .chart-container .col-sm-6 {
        width: 100%;
    }

    .secondChart .position-absolute img {
        width: 39px;
    }
}



@media screen and (max-device-width: 991px) and (orientation: landscape) {
    .toggleactive .side-bar {
        margin-top: 9%;
    }

    .cardSection .col-sm-4 {
        width: 100%;
    }

    .chart-container .col-sm-3 {
        order: 1;
        width: 50%;
    }

    .chart-container .col-sm-6 {
        width: 100%;
    }

    .secondChart .position-absolute img {
        width: 39px;
    }
}

/* styles for iPad Mini */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {

    .toggleactive .side-bar {
        top: 0;
        transition: 0.6s ease;
    }

    .cardSection .col-sm-4 {
        width: 100%;
    }

    .chart-container .col-sm-3 {
        order: 1;
        width: 50%;
    }

    .chart-container .col-sm-6 {
        width: 100%;
    }

    .left_icon {
        top: 39%;
        left: 2%;
    }

    .BreakevenJobCostText {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0px 0;
    }
}


/* iPad Pro portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) and (orientation: portrait) {
    .row_one .col-md-6, .row_two .col-md-4, .row_two .col-md-8 {
        width: 100%;
    }

    .cardSection .col-sm-4 {
        width: 100%;
    }

    .chart-container .col-sm-3 {
        order: 1;
        width: 50%;
    }

    .chart-container .col-sm-6 {
        width: 100%;
    }

    .toggleactive .side-bar {
        top: 8%;
        transition: 0.6s ease;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
    .row_one .col-md-6, .row_two .col-md-4, .row_two .col-md-8 {
        width: 100%;
    }



}

/* styles for iPad in landscape mode */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .toggleactive .side-bar {
        margin-top: 0%;
        transition: 0.6s ease;
    }
}

/* styles for iPad in landscape mode */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .toggleactive .side-bar {
        margin-top: 2%;
        transition: 0.6s ease;
    }
}

@media only screen and (min-width: 991px) and (max-width: 1600px) {


    .carousel-indicators {
        right: 10px !important;
    }

    .carousel-control-prev {
        left: -111px !important;

    }

    .carousel-control-next {
        left: -58px !important;

    }


    .login-form {
        width: 65%;
    }
}


@media only screen and (min-width: 1024px) and (max-width: 1500px) {
    .cardSection .col-sm-4 {
        width: 100%;
    }

    .chart-container .col-sm-3 {
        order: 1;
        width: 50%;
    }

    .chart-container .col-sm-6 {
        width: 100%;
    }

    .secondChart .position-absolute img {
        width: 39px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1280px) {
    .cardSection .col-sm-4 {
        width: 100%;
    }

    .chart-container .col-sm-3 {
        order: 1;
        width: 50%;
    }

    .chart-container .col-sm-6 {
        width: 100%;
    }

}